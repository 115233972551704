import { createApp, ref, watch, onMounted } from 'vue'

export default function headapp() {
  const menu = ref(false)
  const hidemenu = ref(false)

  const app = createApp({
    setup() {
			onMounted(()=>{
				setTimeout(()=>{
					hidemenu.value = true
				},500)
			}),
      watch(menu, (newValue) => {
        if (newValue) {
          document.body.style.position = 'fixed';
          document.body.style.top = `-${window.scrollY}px`;
          document.body.style.width = '100%';
        } else {
          const scrollY = document.body.style.top;
          document.body.style.position = '';
          document.body.style.top = '';
          window.scrollTo(0, parseInt(scrollY || '0') * -1);
          document.body.style.width = '';
        }
      })

      return {
        menu,
				hidemenu,
      }
    },
  })

  app.mount('#headapp')
}
