import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay, EffectFade])
export default function index(){
  const indexSwiper = document.getElementById('indexSwiper')
  if(indexSwiper){
    const swiperParams = {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      grabCursor: true,
      navigation: {
        nextEl: '.business__swipernext',
      },
      breakpoints: {
        1120: {
          slidesPerView: 3,
          spaceBetween: 32,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 24,    
        }
      }
    }
    const swiper = new Swiper(indexSwiper, swiperParams)
  }

}